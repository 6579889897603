import React, {useState} from "react";
import ReactDOM from 'react-dom/client'
import classNames from "classnames";
import CSSTransition from "react-transition-group/CSSTransition";
import Img from "./images/pmm.jpg";
import Img2x from "./images/pmm@2x.jpg";
import ImgW from "./images/pmm.webp";
import ImgW2x from "./images/pmm@2x.webp";

import styles from "./styles.module.css"
import Form from "./Form";
import Results from "./Results";

const Brands = [
    "AEG",
    "Beko",
    "Bosch",
    "Candy",
    "Electrolux",
    "Gorenje",
    "Hansa",
    "Hotpoint-Ariston",
    "Indesit",
    "Maunfeld",
    "Midea",
    "Miele",
    "Samsung",
    "Siemens",
    "Weissgauff",
    "Другое"
]

const Breakdowns = [
    "Гудит или шумит",
    "Не включается",
    "Не выключается",
    "Не греет воду",
    "Не закрывается дверца",
    "Не заливает воду",
    "Не моет посуду",
    "Не сливает воду",
    "Не сушит посуду",
    "Плохо моет",
    "Протекает",
    "Другое"
]

const Types = [
    "Встраиваемая",
    "Отдельно стоящая",
    "Компактная",
    "Промышленная",
    "Другое"
]
export const Dishwasher = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [brand, setBrand] = useState(null)
    const [breakdown, setBreakdown] = useState(null)
    const [type, setType] = useState(null)

    const handleNextBlock = () => {
        setCurrentIndex(Math.min(currentIndex + 1, 4))
    }

    const handleChangeForm = () => {
        setTimeout(handleNextBlock, 300)
    }

    const handleChangeBrand = (e) => {
        setBrand(e.target.value)
        setTimeout(handleNextBlock, 300)
    }

    const handleChangeBreakdown = (e) => {
        setBreakdown(e.target.value)
        setTimeout(handleNextBlock, 300)
    }

    const handleChangeType = (e) => {
        setType(e.target.value)
        setTimeout(handleNextBlock, 300)
    }

    return (
        <div className={styles.calculator}>
            <CSSTransition
                in={currentIndex === 0}
                appear={currentIndex === 0}
                timeout={500}
                classNames="block-next"
            >
                <div className={styles.block}>
                    <div className="row justify-content-md-center">
                        <div className="col-lg-5 d-none d-lg-block">
                            <picture>
                                <source srcSet={`${ImgW}, ${ImgW2x} 2x`} type="image/webp" />
                                <source srcSet={`${Img}, ${Img2x} 2x`} type="image/jpeg" />
                                <img src={Img} className="border-bottom border-1 rounded" />
                            </picture>
                        </div>
                        <div className="col-lg-7">
                            <p>Не знаете что сломалось?</p>
                            <h2 className="display-6 fw-5 text-start mb-5 lh-sm" data-aos="fade-down" data-aos-delay="0">
                                Узнайте стоимость ремонта посудомоечной машины и получите <span className="text-action-1">скидку&nbsp;10%</span>
                            </h2>

                            <p className="mb-10 text-start" data-aos="fade-down" data-aos-delay="150">
                                Ответьте на 3 простых вопроса и получите список возможных поломок и стоимость ремонта, чтобы сразу понимать как
                                планировать свой бюджет.
                            </p>
                            <div className="text-start">
                                <button
                                    onClick={handleNextBlock}
                                    className="btn btn-action-1 py-5 px-10 fw-5"
                                >
                                    Рассчитать стоимость ремонта
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={currentIndex === 1}
                appear={currentIndex === 1}
                timeout={500}
                classNames="block-next"
            >
                <div className={styles.block}>
                    <div className="row justify-content-center">
                        <div className="col-sm-10">
                            <span className={classNames(styles.progress, "bg-bg-2 fw-6 fs-5 rounded px-3 mb-3")}>
                                Вопрос 1 из 3
                            </span>
                            <div className="fs-2 fw-6 text-action-1 mb-2">Укажите марку посудомоечной машины:</div>
                            <p className="fs-5 mb-10">
                                Мы <span className="fw-bold">ремонтируем все марки</span> посудомоечных машин.<br /> Если Вы не нашли свою, то выберите "Другое".
                            </p>
                            <div className={styles.brands}>
                                {
                                    Brands.map((name) => {
                                        return(
                                            <div key={name} className="form-check mb-2 d-flex">
                                                <input
                                                    onChange={handleChangeBrand}
                                                    className="form-check-input"
                                                    name="brand"
                                                    type="checkbox"
                                                    value={name}
                                                    id={`brand-${name}`}
                                                />
                                                <label className="text-dark-1" htmlFor={`brand-${name}`}>
                                                    {name}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={currentIndex === 2}
                appear={currentIndex === 2}
                timeout={500}
                classNames="block-next"
            >
                <div className={styles.block}>
                    <div className="row justify-content-center">
                        <div className="col-sm-10">
                            <span className={classNames(styles.progress, "bg-bg-2 fw-6 fs-5 rounded px-3 mb-3")}>
                                Вопрос 2 из 3
                            </span>
                            <div className="fs-2 fw-6 text-action-1 mb-2">Выберите тип неисправности:</div>
                            <p className="fs-5 mb-10">
                                Если в списке нет вашей поломки, то выберите "Другое", наш специалист свяжется с вами для уточнения поломки.
                            </p>
                            <div className={styles.breakdowns}>
                                {
                                    Breakdowns.map((name, index) => {
                                        return(
                                            <div key={`breakdown-${index}`} className="form-check mb-2 d-flex">
                                                <input
                                                    onChange={handleChangeBreakdown}
                                                    className="form-check-input"
                                                    name="breakdown"
                                                    type="checkbox"
                                                    value={name}
                                                    id={`breakdown-${index}`}
                                                />
                                                <label className="text-dark-1" htmlFor={`breakdown-${index}`}>
                                                    {name}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={currentIndex === 3}
                appear={currentIndex === 3}
                timeout={500}
                classNames="block-next"
            >
                <div className={styles.block}>
                    <div className="row justify-content-center">
                        <div className="col-sm-10">
                            <span className={classNames(styles.progress, "bg-bg-2 fw-6 fs-5 rounded px-3 mb-3")}>
                                Вопрос 3 из 3
                            </span>
                            <div className="fs-2 fw-6 text-action-1 mb-2">
                                Выберите тип посудомоечной машины:
                            </div>
                            <p className="fs-5 mb-10">
                                Мы ремонтируем все типы посудомоечных машин.
                            </p>
                            <div className={styles.types}>
                                {
                                    Types.map((name, index) => {
                                        return(
                                            <div key={`type-${index}`} className="form-check mb-2 d-flex">
                                                <input
                                                    onChange={handleChangeType}
                                                    className="form-check-input"
                                                    name="type"
                                                    type="checkbox"
                                                    value={name}
                                                    id={`type-${index}`}
                                                />
                                                <label className="text-dark-1" htmlFor={`type-${index}`}>
                                                    {name}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={currentIndex === 4}
                appear={currentIndex === 4}
                timeout={500}
                classNames="block-next"
            >
                <div className={styles.block}>
                    <div className="row justify-content-center">
                        <div className="col-sm-10 col-lg-8">
                            <Results breakdown={breakdown} type="dishwasher" />

                            <h2 className="mb-2 mt-8 fw-5 text-action-1">
                                Ваша скидка 10%:
                            </h2>
                            <p className="mb-5">
                                Чтобы забрать скидку оставьте свой номер телефона и мы зафиксируем её.
                                Воспользоваться скидкой Вы можете когда вам будет удобно.
                            </p>
                            <Form serviceId={3} breakdown={breakdown} brand={brand} type={type} />
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

const mountNode = document.getElementById('Calculators/Dishwasher');

document.addEventListener('DOMContentLoaded', () => {
    if (mountNode) {
        const root = ReactDOM.createRoot(mountNode);
        root.render(
            <Dishwasher />
        )
    }
})

