import React, {useEffect, useState} from "react";

const Results = ({breakdown, type}) => {
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(null)

    useEffect(() => {
        if (breakdown) {
            const token = document.querySelector('[name=csrf-token]').content
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': token
                },
                body: JSON.stringify({
                    breakdown: breakdown,
                })
            };

            fetch(`/calculators/${type}`, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        setLoading(false)
                        setResult(result)
                    },
                    (error) => {
                        setLoading(false)
                    }
                )
        }
    }, [breakdown])

    if (!result) {
        return null;
    }

    if (loading) {
        return (
            <div className="p-8 d-flex flex-column align-items-center justify-content-center">
                <div style={{width: '3rem', height: '3rem'}} className="spinner-border text-action-1" role="status">
                </div>
                <div className="fs-3 text-dark-1">Загружаем данные...</div>
                <div className="text-dark-2">Не закрывайте страницу</div>
            </div>
        )
    }

    return (
        <>
            <h2 className="mb-2 fw-5">Результат:</h2>
            <p className="mb-5">
                Возможные решения при данной неисправности (в % указана вероятность поломки)
            </p>


            <table className="table">
                <thead>
                    <tr>
                        <th>%</th>
                        <th>Решение</th>
                        <th width={170}>Стоимость</th>
                    </tr>
                </thead>
                <tbody>
                {
                    result.map((item, index) => {
                        return (
                            <tr key={`result${index}0`}>
                                <td className="py-4">{item.probability}%</td>
                                <td className="py-4">
                                    <b>{item.name}</b>
                                    <p className="mb-0 fs-5">{item.desc}</p>
                                </td>
                                <td className="py-4">
                                    <div className="text-black fw-bold">
                                        от {item.price}&nbsp;руб.
                                    </div>
                                    {item.price_spares && <div className="fs-5">+ запчасти от&nbsp;{item.price_spares}&nbsp;руб</div>}
                                    {item.price_materials && <div className="fs-5">+ материалы от&nbsp;{item.price_materials}&nbsp;руб</div>}
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

            {/*{*/}
            {/*    result.map((item, index) => {*/}
            {/*        return (*/}
            {/*            <div key={`result${index}`} className="row align-items-center justify-content-md-between border-bottom border-1 mb-5 py-3 bg-bg-3 rounded">*/}
            {/*                <div className="col-12 col-md-7 col-lg-7 mb-3 mb-md-0">*/}
            {/*                    <h3 className="mb-3 fs-3 fw-5">*/}
            {/*                        {item.name}*/}
            {/*                    </h3>*/}
            {/*                    <p className="mb-0">{item.desc}</p>*/}
            {/*                    <em className="fw-light fs-5 rounded">*/}
            {/*                        Вероятность данной поломки {item.probability}%*/}
            {/*                    </em>*/}
            {/*                </div>*/}
            {/*                <div className="col-12 col-md-5 col-lg-5 ms-lg-auto text-md-end">*/}
            {/*                    <div className="fs-1 text-black">*/}
            {/*                        от {item.price}&nbsp;руб.*/}
            {/*                    </div>*/}
            {/*                    {item.price_spares && <div>+ запчасти от {item.price_spares} руб</div>}*/}
            {/*                    {item.price_materials && <div>+ материалы от {item.price_materials} руб</div>}*/}

            {/*                    <div></div>*/}
            {/*                    <a*/}
            {/*                        role="button"*/}
            {/*                        className="border-6 border-bottom border-action-6 text-action-6 cursor-pointer fs-5 mb-2"*/}
            {/*                        data-bs-toggle="tooltip"*/}
            {/*                        data-bs-trigger="click"*/}
            {/*                        data-bs-custom-class="price_includes"*/}
            {/*                        data-bs-html="true"*/}
            {/*                        data-bs-title=""*/}
            {/*                    >*/}
            {/*                        Что входит в цену?*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })*/}
            {/*}*/}
        </>
    )
}

export default Results