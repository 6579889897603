import React, {useRef, useState} from "react";
import {IMaskInput} from "react-imask";
import ReactDOM from "react-dom/client";
import {Dishwasher} from "./Calculators/Dishwasher";

export const Form = ({serviceId, branchId}) => {
    const nameInputRef = useRef(null);
    const phoneInputRef = useRef(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [bug, setBug] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const phone = phoneInputRef.current.value;

        if (!phone.match(/\+375 \(\d\d\) \d\d\d-\d\d-\d\d/)) {
            setError(true);
            return false;
        }

        setError(false)
        setLoading(true)
        const token = document.querySelector('[name=csrf-token]').content
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token
            },
            body: JSON.stringify({
                name: nameInputRef.current.value,
                phone,
                service_id: serviceId,
                branch_id: branchId
            })
        };

        fetch("/leads", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false)
                    setBug(false)
                    setSuccess(true)
                    setTimeout(() => setSuccess(false), 10000)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setLoading(false)
                    setBug(true)
                }
            )
    }

    if (loading) {
        return (
            <div className="p-8 d-flex flex-column align-items-center justify-content-center">
                <div style={{width: '3rem', height: '3rem'}} className="spinner-border text-action-1" role="status">
                </div>
                <div className="fs-3 text-dark-1">Отправляем данные...</div>
                <div className="text-dark-2">Не закрывайте страницу</div>
            </div>
        )
    }

    if (success) {
        return (
            <div id="formSuccess" className="p-8 bg-action-8 d-flex flex-column align-items-center justify-content-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    xmlSpace="preserve"
                    width={48}
                    height={48}
                >
                    <circle
                        style={{
                            fill: "#25ae88",
                        }}
                        cx={25}
                        cy={25}
                        r={25}
                    />
                    <path
                        style={{
                            fill: "none",
                            stroke: "#fff",
                            strokeWidth: 2,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: 10,
                        }}
                        d="M38 15 22 33l-10-8"
                    />
                </svg>
                <h2 className="fs-3 fw-normal">
                    Спасибо! Ваша заявка принята
                </h2>
                <p className="remove-br-md mb-0">
                    В ближайшее время наш менеджер свяжется с Вами
                </p>
                <em className="fs-5">
                    Это окно можно закрыть
                </em>
            </div>
        )
    }

    return (
        <form
            action="/leads"
            method="post"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
            className="p-8"
        >
            {
                bug && <div className="alert alert-action-5" role="alert">
                    Извините, произошла ошибка. Отправьте форму еще раз или позвоните нам сами.
                </div>
            }
            <div className="row align-items-center">
                <div className="col-md-4 text-md-end">
                    Ваше имя:
                </div>
                <div className="col-md-8">
                    <div className="input-group">
                        <div className="input-group-text border-end-0 border-dark-3 ps-4 pe-2 bg-bg-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={20}
                                height={20}
                                className="fill-dark-2"
                                viewBox="0 0 48 48"
                            >
                                <path d="M24 4c-5.505 0-10 4.495-10 10s4.495 10 10 10 10-4.495 10-10S29.505 4 24 4zm0 3c3.884 0 7 3.116 7 7s-3.116 7-7 7-7-3.116-7-7 3.116-7 7-7zM11.979 28C9.799 28 8 29.799 8 31.979V33.5c0 3.604 2.28 6.392 5.307 8.063C16.334 43.233 20.168 44 24 44s7.666-.767 10.693-2.438c2.582-1.424 4.524-3.7 5.069-6.562h.24v-3.021c0-2.18-1.8-3.979-3.98-3.979H11.978zm0 3H36.02c.559 0 .981.42.981.979V32H37v1.5c0 2.396-1.346 4.108-3.756 5.438C30.834 40.267 27.418 41 24 41s-6.834-.733-9.244-2.063S11 35.897 11 33.5v-1.521c0-.559.42-.979.979-.979z" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            ref={nameInputRef}
                            name="lead[customer_name]"
                            placeholder="Ваше имя?"
                            className="form-control border-start-0 border-dark-3 ps-1 bg-bg-3 fs-5"
                        />
                    </div>
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-md-4 text-md-end">
                    Ваш телефон<sup className="text-action-5">*</sup>:
                </div>
                <div className="col-md-8">
                    <div className="input-group">
                        <div className="input-group-text border-end-0 border-dark-3 ps-4 pe-2 bg-bg-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={20}
                                height={20}
                                className="fill-dark-2"
                                viewBox="0 0 48 48"
                            >
                                <path d="M19.041 4.014a5.595 5.595 0 0 0-1.994.215l-2.367.7c-2.523.747-4.494 2.748-5.16 5.274-1.481 5.618.213 12.267 4.62 19.752 4.402 7.475 9.418 12.225 15.083 13.775a7.677 7.677 0 0 0 7.234-1.763l1.8-1.67c2.016-1.871 2.316-4.987.688-7.201l-2.601-3.533c-1.395-1.895-3.853-2.701-6.114-2.022l-4.07 1.219c-.17.05-.58-.183-.935-.49l-.002-.002c-.634-.551-1.512-1.665-2.452-3.258v-.002c-1.007-1.71-1.436-2.83-1.609-3.49-.172-.661-.13-.742-.105-.965a.473.473 0 0 1 .156-.297l.002-.002 3.035-2.775a5.438 5.438 0 0 0 1.283-6.268l-1.83-3.992v-.002c-.861-1.878-2.688-3.06-4.662-3.203zm-.217 2.992c.918.068 1.764.62 2.15 1.463l1.833 3.992c.443.966.22 2.072-.58 2.805l-3.036 2.773a3.482 3.482 0 0 0-1.115 2.176v.002c-.025.22-.083 1.036.184 2.058.267 1.023.818 2.375 1.926 4.256h.002c1.033 1.753 2.01 3.085 3.068 4.004h.002c.343.298 1.783 1.678 3.742 1.106l.006-.002 4.088-1.225a2.592 2.592 0 0 1 2.834.928l2.601 3.533a2.412 2.412 0 0 1-.312 3.223l-1.801 1.672c-1.152 1.07-2.862 1.488-4.402 1.066-4.58-1.254-9.111-5.31-13.287-12.402-4.182-7.102-5.49-12.975-4.307-17.465.391-1.482 1.59-2.712 3.111-3.162l2.365-.702c.309-.09.622-.122.928-.1z" />
                            </svg>
                        </div>
                        <IMaskInput
                            mask={"{+375} (00) 000-00-00"}
                            inputRef={phoneInputRef}
                            lazy={false}
                            placeholder="Номер телефона"
                            className="form-control border-start-0 border-dark-3 ps-1 bg-bg-3 fs-5"
                        />
                    </div>
                    {
                        error && <div className="text-action-5 fs-5">
                            Укажите номер телефона.
                        </div>
                    }
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-md-4">
                </div>
                <div className="col-md-8">
                    <button type="submit" className="btn btn-glare rounded btn-action-1 fs-5 fw-normal w-100">
                        Оставить заявку
                        <span className="glare"></span>
                    </button>
                    <p className="text-muted fs-6 mt-3 mb-0">Нажимая на кнопку, я даю согласие на обработку своих
                        персональных данных</p>
                </div>
            </div>
        </form>
    )
}

const mountNode = document.getElementById('Form');
const propsJSON = mountNode.getAttribute('data-react-props');
const props = JSON.parse(propsJSON);
document.addEventListener('DOMContentLoaded', () => {
    const root = ReactDOM.createRoot(mountNode);
    root.render(
        <Form {...props} />
    )
})